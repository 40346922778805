<template>
  <!-- 查中标 -->
  <div style="margin-top: 120px;">
    <search-nav :active="2"></search-nav>
    <!-- <el-affix :offset="120"> -->
      <el-row>
        <div class="container">
          <!--输入框-->
          <el-row style="margin-bottom: 30px">
            <el-col :span="13">
              <div>
                <el-autocomplete
                  style="width: 100%"
                  clearable
                  class="inline-input"
                  v-model="conditions.keyword"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入中标项目关键词"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                  size="medium"
                  input-style="border-bottom-right-radius:0px;border-top-right-radius:0px;height:50px"
                >
                </el-autocomplete>
              </div>
            </el-col>
            <el-col :span="4">
              <el-button type="primary" style="background: #FC7A1E;border-color: #FC7A1E;" class="search-btn-search"  size="medium" @click="queryKeywordClick">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </el-row>
    <!-- </el-affix> -->
    <el-row>
      <div class="container">
        <!--筛选条件-->
        <div class="condition-content">
          <div class="dis-bet-cen condition-title">
            <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">筛选条件</span>
            <link-to-advanced></link-to-advanced>
          </div>
          <div style="padding: 10px 0 20px 0;">
          <el-row v-if="tags.length > 0" class="dis-cen-sta already-choose">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #5D6FE9;height: 40px;line-height: 40px;">已选条件：</div></el-col>
            <el-col :span="20" style="margin-top: 5px;">
              <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                <el-tag style="margin-right: 10px;margin-bottom: 10px;" v-for="(tag, index) in tags" :key="tag.name" closable :type="tag.type" @close="closeTag(tag, index)">
                  {{tag.name}}
                </el-tag>
              </div>
            </el-col>
            <el-col :span="2">
              <div @click="closeAllTag" class="grid-content bg-purple font-choose-text">清除全部</div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.provinceModel.name" class="dis-cen-sta already-choose">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;height: 40px;line-height: 40px;" >项目地区：</div></el-col>
            <el-col :span="22">
              <div class="grid-content bg-purple">
                <arrangement-radio :valueArr="arrangementRadioProvinceValueArr" :defaultShowNum="arrangementRadioProvinceDefaultShowNum" @handleChange="arrangementRadioProvinceChange"></arrangement-radio>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.cityModel.name && conditions.provinceModel.name && conditions.provinceModel.name != '北京市' && conditions.provinceModel.name != '天津市' && conditions.provinceModel.name != '上海市' && conditions.provinceModel.name != '重庆市'" class="dis-cen-sta already-choose">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;height: 40px;line-height: 40px;">城市：</div></el-col>
            <el-col :span="22">
              <div class="grid-content bg-purple">
                <arrangement-radio :valueArr="arrangementRadioCityValueArr" :defaultShowNum="arrangementRadioCityDefaultShowNum" @handleChange="arrangementRadioCityChange"></arrangement-radio>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.moneyModel.key" class="dis-cen-cen ">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;">中标金额：</div></el-col>
            <el-col :span="22">
              <div class="grid-content bg-purple">
                <registered-capital @handleChange="registeredCapitalChange"></registered-capital>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.industryTagModel.name" class="dis-cen-sta ">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;height: 42px;line-height: 42px;">专业分类：</div></el-col>
            <el-col :span="22">
              <div class="grid-content bg-purple">
                <arrangement-radio :valueArr="arrangementRadioIndustryTagValueArr" :defaultShowNum="arrangementRadioIndustryTagDefaultShowNum" @handleChange="arrangementRadioIndustryTagChange"></arrangement-radio>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.businessModel.name" class="dis-cen-cen ">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;">行业分类：</div></el-col>
            <el-col :span="22">
              <div class="grid-content bg-purple">
                <arrangement-radio :valueArr="arrangementRadioBusinessValueArr" :defaultShowNum="arrangementRadioBusinessDefaultShowNum" @handleChange="arrangementRadioBusinessChange"></arrangement-radio>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.companyKeyword" class="dis-cen-cen">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;">中标企业：</div></el-col>
            <el-col :span="22">
              <el-row class="dis-sta-cen">
                <el-col :span="13">
                  <div>
                    <el-input v-model="companyKeyword" placeholder="请输入企业名称关键词" size="medium" clearable></el-input>
                  </div>
                </el-col>
                <el-col :span="4" style="float: left; margin: 0px 0px 0px 10px;">
                  <el-button type="" style="float: left"  size="medium" @click="companyKeywordClick">确定</el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.achievementDate" class="dis-sta-cen" style="margin-top: 10px;">
            <el-col :span="2">
              <div class="grid-content bg-purple" style="font-size: 14px;color: #999999;">中标时间：</div>
            </el-col>
            <el-col :span="22" class="dis-sta-cen">
              <el-date-picker
                v-model="conditions.achievementDate"
                type="monthrange"
                :editable="false"
                size="medium"
                @blur="achievementDateChange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row class="dis-cen-cen" style="margin-top: 15px;">
            <el-col :span="2">
              <div class="grid-content bg-purple" >
                <el-button type="primary" size="medium" @click="queryClick">查询</el-button>
              </div>
            </el-col>
          </el-row>
          </div>
        </div>
        <!--内容卡片 柏成大佬-->
        <el-row style="margin-top: 30px;margin-bottom: 30px">
          <el-col :span="24">
            <div class="condition-content">
              <div class="dis-bet-cen condition-title">
                <span class="screening-conditions-title">共查询到 <span class="font-color-red">{{ page.total }}</span> 条符合条件的中标</span>
                <span class="advanced-query-title">
                <el-select v-model="optionsValue" placeholder="默认排序">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
              </span>
              </div>
              <div class="condition-content-list">
                <block v-show="!fuzzyDataShow" v-for="(item, index) of dataList" :key="index">
                  <el-row class="list-padding list-hover">
                    <el-col :span="24">
                      <div class="condition-content-list-name dis-bet-cen">
                        <span style="cursor:pointer;" @click="companyAchievementNameClick(item, index)" v-html="item.projectName || '-'"></span>
                        <span v-if="item.isfocus === '4'">
                          <el-tooltip class="item" effect="dark" content="用户提供了完整的资料数据，但无法在公开网站上核验" placement="bottom-end">
                            <span  class="focus-enterprises-tag-yellow">未核验</span>
                          </el-tooltip>
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="7">企业名称：<span class="font-color-gray" style="cursor: pointer;" @click="companyNameClick(item, index)">{{item.companyName || '-'}}</span></el-col>
                        <el-col :span="5">省份地区：<span class="font-color-gray">{{item.province || '-'}}</span></el-col>
                        <el-col :span="5">城市：<span class="font-color-gray">{{item.city || '-'}}</span></el-col>
                        <el-col :span="5">中标日期：<span class="font-color-gray">{{item.timeSuccessStr || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-divider v-if="(index + 1) != dataList.length"></el-divider>
                </block>
                <el-empty v-if="dataList.length === 0"  :image-size="200"></el-empty>
                <gaussian-blur pageName="company-achievement" v-show="fuzzyDataShow"></gaussian-blur>
              </div>
            </div>
          </el-col>
        </el-row>
        <!--分页组件-->
        <div class="dis-end-sta" style="margin-bottom: 30px">
          <el-pagination
            :hide-on-single-page="true"
            :pager-count="page.pagerCount"
            background
            layout="prev, pager, next"
            v-model:currentPage="page.pageNum"
            @current-change="currentChange"
            :total="page.total">
          </el-pagination>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import ArrangementRadio from '@/components/query-conditions/arrangement-radio' // 省市的平铺单选
import RegisteredCapital from '@/components/query-conditions/registered-capital' // 注册资金
import SearchNav from '@/components/search-nav/search-nav'
import LinkToAdvanced from '@/components/link-to-advanced/link-to-advanced'
import { parseTime } from '@/utils/index' // 工具类
import { ElMessage } from 'element-plus'
import provinceData from '@/assets/pca-code.js'
import { mapGetters } from 'vuex'
import { forwardBG, oldForwardBG } from '@/api/forward.js'
import { getDictByModel } from '@/api/dict'
import GaussianBlur from '@/components/gaussian-blur/gaussian-blur'
import toFixs from '@/utils/toDate'
export default {
  name: 'company-achievement',
  components: {
    ArrangementRadio,
    RegisteredCapital,
    SearchNav,
    LinkToAdvanced,
    GaussianBlur
  },
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'topNav')
    }
    return {
      loginEmit
    }
  },
  data () {
    return {
      provinceData: provinceData,
      oldKeyword: '',
      companyKeyword: '', // 企业名称关键字
      conditions: {
        keyword: this.$route.query.searchVal ? this.$route.query.searchVal : '', // 查询的关键字
        provinceModel: {}, // 已经选择的省份
        cityModel: {}, // 已经选择的市
        moneyModel: {}, // 已经选择的中标金额
        industryTagModel: {}, // 已经选择的专业分类 大行业
        businessModel: {}, // 已经选择的行业分类
        companyKeyword: '', // 已经选择的中标企业
        achievementDate: '', // 已经选择的中标时间 格式 ["2021-11-30T16:00:00.000Z","2022-05-31T16:00:00.000Z"]
        order: '' // 排序规则
      },
      queryConditions: {
        keyword: '', // 查询的关键字
        provinceModel: {}, // 已经选择的省份
        cityModel: {}, // 已经选择的市
        moneyModel: {}, // 已经选择的中标金额
        industryTagModel: {}, // 已经选择的专业分类 大行业
        businessModel: {}, // 已经选择的行业分类
        companyKeyword: '', // 已经选择的中标企业
        achievementDateStart: '', // 2021-11
        achievementDateEnd: '', // 2021-11
        order: '' // 排序规则
      },
      arrangementRadioProvinceValueArr: [], // 平铺单选的组件数据 省
      arrangementRadioProvinceDefaultShowNum: 12, // 平铺单选的默认显示数量 省
      arrangementRadioCityValueArr: [], // 平铺单选的组件数据 市
      arrangementRadioCityDefaultShowNum: 10, // 平铺单选的默认显示数量 市
      arrangementRadioIndustryTagValueArr: [], // 平铺单选的组件数据 专业分类
      arrangementRadioIndustryTagDefaultShowNum: 7, // 平铺单选的默认显示数量 专业分类
      arrangementRadioBusinessValueArr: [], // 平铺单选的组件数据 行业分类
      arrangementRadioBusinessDefaultShowNum: 9, // 平铺单选的默认显示数量 行业分类
      tags: [],
      // 数据
      dataList: [
        // {
        //   name: '厦门柏事特信息技术有限公司',
        //   address: '厦门市软件园二期观日路24号101-C单元',
        //   number: '1041619-00808',
        //   people: '陈京鹭',
        //   isfocus: 1,
        //   tel: '江苏能督办',
        //   inter: 'http://www.xmbest.com',
        //   email: 'xmbest@xmbest.com',
        //   time: '2000-04-28',
        //   money: '5006万元人民币'
        // },
        // {
        //   name: '厦门柏事特信息技术有限公司',
        //   address: '厦门市软件园二期观日路24号101-C单元',
        //   number: '1041619-00808',
        //   people: '陈京鹭',
        //   isfocus: 4,
        //   tel: '江苏能督办',
        //   inter: 'http://www.xmbest.com',
        //   email: 'xmbest@xmbest.com',
        //   time: '2000-04-28',
        //   money: '5006万元人民币'
        // }
      ],
      // 排序的筛选
      optionsValue: '1',
      options: [{
        value: '1',
        label: '默认排序'
      }, {
        value: '2',
        label: '中标时间升序'
      }, {
        value: '3',
        label: '中标时间降序'
      }],
      page: { // 分页使用
        total: 100, // 总条数
        pagerCount: 7,
        pageNum: 1, // 当前页
        pageSize: 10 // 每页条数
      },
      returnLimit: false, // 返回数据是否受限
      fuzzyDataShow: false, // 模糊图片是否显示
      fuzzyData: require('@/assets/images/fuzzy_data.png')
    }
  },
  computed: {
    // vuex获取值
    // this.$store.user.vipData = obj
    ...mapGetters([
      'vipData', // 会员数据
      'idToken'
    ]),
    vipLevelChange: function () {
      const vm = this
      // console.log('计算属性: ' + JSON.parse(vm.vipData).vipLevel)
      if (vm.vipData && JSON.parse(vm.vipData)) {
        return JSON.parse(vm.vipData).vipLevel
      } else {
        return null
      }
    }
  },
  watch: {
    vipLevelChange: function (newValue, oldValue) {
      const vm = this
      // console.log('值变化了')
      // 初始化 省市数据
      vm.initArrangementRadioValueArr()
      // 初始化专业分类
      vm.initArrangementRadioIndustryTagValueArr()
      // 初始化行业分类
      vm.initArrangementRadioBusinessValueArr()
      vm.queryKeywordClick()
    },
    optionsValue () {
      const vm = this
      if (vm.idToken) {
        if (vm.returnLimit) { // 已经返回受限了 就不继续查询了
          vm.page.pageNum = -Math.ceil(Math.random() * 1000)
          vm.fuzzyDataShow = true
        } else {
          // 去查询
          vm.getList()
        }
      } else {
        vm.optionsValue = '0'
        vm.loginEmit()
      }
    }
  },
  mounted () {
    const vm = this
    // 初始化 省市数据
    vm.initArrangementRadioValueArr()
    // 初始化专业分类
    vm.initArrangementRadioIndustryTagValueArr()
    // 初始化行业分类
    vm.initArrangementRadioBusinessValueArr()
    vm.queryKeywordClick()
  },
  methods: {
    // 输入框输入内容触发
    querySearch (queryString, callback) {
      const list = []
      callback(list)
    },
    // 输入框选择之后返回的数据
    handleSelect (item) {
    },
    initArrangementRadioValueArr () {
      const vm = this
      vm.arrangementRadioProvinceValueArr = vm.provinceData.pcaData
    },
    initArrangementRadioIndustryTagValueArr () {
      const vm = this
      const data = {
        dictCodeLike: '0017'
      }
      getDictByModel(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let i = 0; i < data.length; i++) {
            if (data[i].dictNo !== '0017') {
              vm.arrangementRadioIndustryTagValueArr.push({
                name: data[i].dictName, // 显示的值
                value: data[i].dictNo, // 实际的值
                tag: '1' // 为了带其他东西冗余
              })
            }
          }
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initArrangementRadioBusinessValueArr () {
      const vm = this
      const data = {
        dictCodeLike: '0014'
      }
      getDictByModel(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let i = 0; i < data.length; i++) {
            if (data[i].dictNo !== '0014') {
              vm.arrangementRadioBusinessValueArr.push({
                name: data[i].dictName, // 显示的值
                value: data[i].dictNo, // 实际的值
                tag: '1' // 为了带其他东西冗余
              })
            }
          }
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 省市组件选择完了的回调 省
    arrangementRadioProvinceChange (item) {
      const vm = this
      vm.arrangementRadioCityValueArr = item.children
      vm.conditions.provinceModel = {
        code: item.code,
        name: item.name
      }
      vm.tags.push({
        name: '省份地区：' + item.name,
        value: 'provinceModel',
        default: {}
      })
    },
    // 省市组件选择完了的回调 市
    arrangementRadioCityChange (item) {
      const vm = this
      vm.conditions.cityModel = {
        code: item.code,
        name: item.name
      }
      vm.tags.push({
        name: '城市：' + item.name,
        value: 'cityModel',
        default: {}
      })
    },
    // 省市组件选择完了的回调 专业分类
    arrangementRadioIndustryTagChange (item) {
      const vm = this
      console.log('回调: ' + JSON.stringify(item))
      vm.conditions.industryTagModel = item
      vm.tags.push({
        name: '专业分类：' + item.name,
        value: 'industryTagModel',
        default: {}
      })
    },
    // 省市组件选择完了的回调 行业分类
    arrangementRadioBusinessChange (item) {
      const vm = this
      console.log('回调: ' + JSON.stringify(item))
      vm.conditions.businessModel = item
      vm.tags.push({
        name: '行业分类：' + item.name,
        value: 'businessModel',
        default: {}
      })
    },
    // 注册资金组件选择完了的回调
    registeredCapitalChange (item) {
      const vm = this
      console.log('回调: ' + JSON.stringify(item))
      vm.conditions.moneyModel = item
      vm.tags.push({
        name: '中标金额：' + item.key,
        value: 'moneyModel',
        default: {}
      })
    },
    // 选择时间触发 ["2021-11-30T16:00:00.000Z","2022-10-31T16:00:00.000Z"]
    achievementDateChange () {
      const vm = this
      if (vm.conditions.achievementDate) {
        vm.tags.push({
          name: '中标时间：' + parseTime(vm.conditions.achievementDate[0], '{y}年{m}月') + '-' + parseTime(vm.conditions.achievementDate[1], '{y}年{m}月'),
          value: 'achievementDate',
          default: ''
        })
      }
    },
    // 中标企业的确定按钮
    companyKeywordClick () {
      const vm = this
      if (vm.companyKeyword) {
        vm.conditions.companyKeyword = vm.companyKeyword
        vm.companyKeyword = ''
        vm.tags.push({
          name: '中标企业：' + vm.conditions.companyKeyword,
          value: 'companyKeyword',
          default: ''
        })
      } else {
        return ElMessage.warning({
          message: '请先输入企业名称关键字',
          type: 'warning'
        })
      }
    },
    // 关闭Tag触发
    closeTag (item, index) {
      const vm = this
      vm.tags.splice(index, 1)
      vm.conditions[item.value] = item.default
      if (item.value === 'provinceModel') {
        vm.conditions.cityModel = {}
        for (let i = 0; i < vm.tags.length; i++) {
          if (vm.tags[i].value === 'cityModel') {
            return vm.closeTag(vm.tags[i], i)
          }
        }
      }
    },
    // 清除全部Tag
    closeAllTag () {
      const vm = this
      vm.tags = []
      vm.conditions.provinceModel = {}
      vm.conditions.cityModel = {}
      vm.conditions.moneyModel = {}
      vm.conditions.industryTagModel = {}
      vm.conditions.businessModel = {}
      vm.conditions.companyKeyword = ''
      vm.conditions.achievementDate = ''
    },
    // 翻页时触发
    currentChange (cuttentPage) {
      const vm = this
      if (vm.idToken) {
        if (vm.returnLimit) { // 已经返回受限了 就不继续查询了
          vm.page.pageNum = -Math.ceil(Math.random() * 1000)
          vm.fuzzyDataShow = true
        } else {
          document.documentElement.scrollTop = 0
          vm.page.pageNum = cuttentPage
          vm.getList()
        }
      } else {
        vm.page.pageNum = -Math.ceil(Math.random() * 1000)
        vm.loginEmit()
      }
    },
    // 关键字查询
    queryKeywordClick () {
      const vm = this
      // 如果跟上次查询的不一样就清理下面
      if (vm.oldKeyword !== vm.conditions.keyword) {
        vm.closeAllTag()
        vm.queryConditions.provinceModel = {}
        vm.queryConditions.cityModel = {}
        vm.queryConditions.moneyModel = {}
        vm.queryConditions.industryTagModel = {}
        vm.queryConditions.businessModel = {}
        vm.queryConditions.companyKeyword = ''
        vm.queryConditions.achievementDateStart = ''
        vm.queryConditions.achievementDateEnd = ''
      }
      vm.oldKeyword = vm.conditions.keyword
      vm.queryConditions.keyword = vm.conditions.keyword
      vm.page.pageNum = 1
      vm.page.total = 0
      vm.dataList = []
      // 去查询
      vm.getList()
    },
    // 查询
    queryClick () {
      const vm = this
      if (vm.idToken) {
        if (vm.returnLimit) { // 已经返回受限了 就不继续查询了
          vm.page.pageNum = -Math.ceil(Math.random() * 1000)
          vm.fuzzyDataShow = true
        } else {
          // 获取省
          vm.queryConditions.provinceModel = JSON.parse(JSON.stringify(vm.conditions.provinceModel))
          // 获取市
          vm.queryConditions.cityModel = JSON.parse(JSON.stringify(vm.conditions.cityModel))
          // 获取中标金额
          vm.queryConditions.moneyModel = JSON.parse(JSON.stringify(vm.conditions.moneyModel))
          // 获取专业
          vm.queryConditions.industryTagModel = JSON.parse(JSON.stringify(vm.conditions.industryTagModel))
          // 获取行业
          vm.queryConditions.businessModel = JSON.parse(JSON.stringify(vm.conditions.businessModel))
          // 获取中标企业
          vm.queryConditions.companyKeyword = vm.conditions.companyKeyword
          // 中标时间
          if (vm.conditions.achievementDate) {
            vm.queryConditions.achievementDateStart = parseTime(vm.conditions.achievementDate[0], '{y}-{m}')
            vm.queryConditions.achievementDateEnd = parseTime(vm.conditions.achievementDate[1], '{y}-{m}')
          } else {
            vm.queryConditions.achievementDateStart = null
            vm.queryConditions.achievementDateEnd = null
          }
          vm.page.pageNum = 1
          vm.page.total = 0
          vm.dataList = []
          // 去查询
          vm.getList()
        }
      } else {
        vm.loginEmit()
      }
    },
    getList () {
      const vm = this
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let orderField = null
      let orderType = null
      // 处理排序
      if (vm.optionsValue === '1') {
        orderField = null
        orderType = null
      } else if (vm.optionsValue === '2') {
        orderField = 'time_success'
        orderType = 'asc'
      } else if (vm.optionsValue === '3') {
        orderField = 'time_success'
        orderType = 'desc'
      }
      const data = {
        dateBackgroundUrl: vm.idToken ? '/esAchievement/getESAchievementListByModelSecond' : '/esFake/esAchievement/getESAchievementListByModelSecond',
        dateBackgroundRequestType: 'get',
        data: {
          achievementProvince: vm.queryConditions.provinceModel.name ? vm.queryConditions.provinceModel.name : null,
          achievementCity: vm.queryConditions.cityModel.name ? vm.queryConditions.cityModel.name : null,
          business: vm.queryConditions.businessModel.name ? vm.queryConditions.businessModel.name : null,
          industryTagModel: vm.queryConditions.industryTagModel.name ? vm.queryConditions.industryTagModel.name : null, // 专业未来加入到es中
          timeSuccessStart: vm.queryConditions.achievementDateStart ? vm.queryConditions.achievementDateStart : null,
          timeSuccessEnd: vm.queryConditions.achievementDateEnd ? vm.queryConditions.achievementDateEnd : null,
          moneyStart: vm.queryConditions.moneyModel.startValue ? vm.queryConditions.moneyModel.startValue : null,
          moneyEnd: vm.queryConditions.moneyModel.endValue ? vm.queryConditions.moneyModel.endValue : null,
          companyName: vm.queryConditions.companyKeyword ? vm.queryConditions.companyKeyword : null,
          projectName: vm.queryConditions.keyword ? vm.queryConditions.keyword : null,
          pageNum: vm.page.pageNum,
          pageSize: vm.page.pageSize,
          orderField: orderField,
          orderType: orderType
        }
      }
      const forward = vm.idToken ? oldForwardBG : forwardBG
      forward(data).then(result => {
        if (result.code === 0 || result.code === 97 || result.code === 98) {
          if (result.code === 97) {
            vm.returnLimit = true
            vm.fuzzyDataShow = false
          } else if (result.code === 98) {
            vm.returnLimit = true
            vm.fuzzyDataShow = true
          } else {
            vm.returnLimit = false
            vm.fuzzyDataShow = false
          }
          const data = result.data ? result.data : []
          const thisDataList = []
          const companyIds = []
          for (let i = 0; i < data.length; i++) {
            if (i === 0) {
              vm.page.total = data[i].dataTotal
            }
            data[i].projectName = vm.returnLimit || !vm.idToken ? toFixs.getcompanyName(data[i].projectName) : data[i].projectName
            if (data[i].timeSuccess) {
              data[i].timeSuccessStr = parseTime(data[i].timeSuccess, '{y}-{m}-{d}')
            }
            thisDataList.push(data)
            companyIds.push(data[i].id)
          }
          vm.dataList = data
          // 去查询ES中没有的字段
          if (companyIds.length > 0) {
            vm.getListOtherData(companyIds)
          }
          loading.close()
        } else {
          loading.close()
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getListOtherData (companyIds) {
      const vm = this
      const data = {
        dateBackgroundUrl: '/tCompanyAchievement/getCompanyAchievementByIds',
        dateBackgroundRequestType: 'get',
        data: {
          ids: companyIds.toString()
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let j = 0; j < vm.dataList.length; j++) {
            for (let i = 0; i < data.length; i++) {
              if (vm.dataList[j].id === data[i].id) {
                vm.dataList[j].isfocus = data[i].recordGrade + ''
              }
            }
          }
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 点击了 公司名 跳转到公司详情
    companyAchievementNameClick (item, index) {
      const vm = this
      sessionStorage.removeItem('company-achievement-details-item')
      console.log(JSON.stringify(item) + ' --- ' + index)
      sessionStorage.setItem('company-achievement-details-item', JSON.stringify(item))
      const routeData = vm.$router.resolve({
        name: 'company-achievement-details'
      })
      window.open(routeData.href, '_blank')
    },
    // 点击了 公司名 跳转到公司详情
    companyNameClick (item, index) {
      const vm = this
      vm.$store.dispatch('setBusId', item.companyId)
      vm.$router.push({ name: 'enterprise-details' })
    }
  }
}
</script>

<style scoped="scoped">
.input-search {
  margin-top: 120px;
}
/deep/ .el-input-group__append, .el-input-group__prepend {
  background-color: #5D6FE9;
  border: none;
  color: #fff;
  width: 70px;
}
.conditions-font-title {
  font-size: 14px;
  color: #999;
  display: block;
  height: 40px;
  line-height: 40px;
}
.focus-enterprises-tag {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #5D6FE9;
  color: #5D6FE9;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-gray {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #E6E6E6;
  color: #666;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-yellow {
  color: #EA9920;
  padding: 0 5px;
  border: 1px solid #EA9920;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}
.list-hover:hover{
  background-color: #F3F9FD;
}
.el-divider--horizontal {
  margin: 0;
}
/deep/ .el-input.el-input--medium.el-input--suffix {
  line-height: 50px !important;
}
</style>
